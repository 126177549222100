@import "variables";

footer {
  margin-top: 4rem;
  background: $gold;
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 2rem;
  .wrapper {
    padding: 0 0.5rem;
    margin: auto;
    max-width: $max_width;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .branding {
      svg {
        width: 269px;
        height: 74px;
      }
    }
    .links {
      h4 {
        font-size: 20px;
        font-weight: 700;
        line-height: 26.6px;
      }
      font-size: 14px;
      line-height: 21px;
      display: grid;
      grid-gap: 1rem;
      grid-auto-rows: min-content;
    }
  }
  .social {
    .social_icons {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 1rem;
    }
  }
  .watermark {
    z-index: -1;
    display: grid;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
