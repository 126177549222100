@import "variables";

.landingPage {
  display: grid;
  .shelf {
    margin: auto;
    width: 100%;
    max-width: $max_width;
    padding: 2rem 0.5rem;
    z-index: 1;
    .shelf-head {
      z-index: 10;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.5rem 0;
      .shelf-title {
        display: flex;
        grid-gap: 1rem;
        align-items: center;
      }
      .more {
        display: flex;
        align-items: center;
        grid-gap: 10px;
      }
    }
    .content {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      grid-gap: 1.25rem;
      .seller {
        border-radius: 0.5rem;
        overflow: hidden;
        filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
        background: white;
        .seller-benner {
          height: 6rem;
          width: 100%;
          object-fit: cover;
        }
        .seller-detail {
          display: flex;
          padding: 0.5rem 1rem;
          padding-top: 0;
          margin-top: -0.6rem;
          grid-gap: 1rem;
          align-items: center;
          .seller-profile-pic {
            height: 58px;
            width: 58px;
            border-radius: 50%;
            padding: 2px;
            border: 1px solid rgba(0, 0, 0, 0.12);
            background: white;
            position: relative;
            .rank-badge {
              position: absolute;
              bottom: 0;
              right: 0;
            }
            img {
              display: grid;
              border-radius: 50%;
              object-fit: cover;
              height: 100%;
              width: 100%;
            }
          }
          .detail {
            margin-top: 0.5rem;
            display: grid;
            height: min-content;
            a {
              font-weight: 600;
              font-size: 14px;
            }
            .usdc {
              font-size: 12px;
              color: $text_gray;
            }
          }
        }
      }
    }
    &.explore {
      .more {
        .devider {
          height: 1.5rem;
          width: 1px;
          background: #d5d5d5;
        }
      }
      .filters {
        align-items: center;
        display: flex;
        grid-gap: 0.5rem;
        font-size: 14px;
        .chip {
          border-radius: 4px;
          padding: 0.4rem 0.75rem;
          border: 1px solid #d5d5d5;
          cursor: pointer;
          &.selected {
            background-color: $text_black;
            color: white;
            border-color: $text_black;
          }
        }
      }
    }
  }
}
