@import "variables";

.product-thumb {
  position: relative;
  z-index: 1;
  .thumbnail {
    width: 100%;
    height: 15rem;
    object-fit: cover;
    display: grid;
    border-radius: 0.5rem 0.5rem 0 0;
  }
  .animated {
    position: absolute;
    top: 10px;
    left: 10px;
    background: #00000080;
    padding: 1px 11px;
    border-radius: 2rem;
  }
  .product-detail {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-gap: 0.5rem;
    padding: 0.7rem;
    padding-bottom: 1.2rem;
    font-size: 14px;
    background: white;
    border-radius: 0 0 0.5rem 0.5rem;
    .product-seller {
      display: flex;
      align-items: center;
      grid-gap: 5px;
      img {
        height: 20px;
        width: 20px;
        object-fit: cover;
        border-radius: 50%;
      }
      .product-favorite {
        margin: auto;
        margin-right: 0;
        align-self: flex-end;
        display: flex;
        align-items: center;
        grid-gap: 5px;
      }
    }
    .devider {
      width: 100%;
      height: 1px;
      background: rgba(0, 0, 0, 0.05);
      margin: 0.25rem 0;
    }
    .price {
      display: block;
      display: flex;
      font-weight: 500;
      grid-gap: 5px;
      align-items: center;
    }
    .bidDetail {
      display: flex;
      justify-content: space-between;
      .label {
        display: block;
        margin-bottom: 4px;
      }
      .countdown {
        display: flex;
        grid-gap: 2px;
        text-align: center;
        span {
          min-width: 26px;
          background: $text_black;
          color: white;
          font-weight: 500;
          padding: 2px 5px;
          border-radius: 4px;
        }
      }
    }
  }
  &.multiple {
    &::after,
    &::before {
      position: absolute;
      left: 50%;
      content: "";
      display: block;
      height: 100%;
      top: 0;
      background: #f7f7f7;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
      border-radius: 0.5rem;
    }
    &::before {
      width: 90%;
      z-index: -3;
      transform: translate(-50%, 1.5%);
    }
    &::after {
      width: 80%;
      transform: translate(-50%, 2.5%);
      z-index: -5;
    }
  }
}
