@import "variables";

.hero {
  min-height: 20rem;
  background: black;
  color: white;
  position: relative;
  overflow-x: hidden;
  .background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    filter: contrast(1.15) brightness(0.9);
  }
  .wrapper {
    height: min-content;
    padding: 2rem 0;
    margin: auto;
    max-width: 100vw;
    .react-multi-carousel-track {
      align-items: center;
    }
    .hero-item {
      width: 100%;
      display: grid;
      max-width: 1350px;
      margin: auto;
      position: relative;
      .hero-title {
        position: absolute;
        top: 10%;
        z-index: 5;
        h3 {
          font-size: 20px;
          text-transform: uppercase;
          margin-bottom: 1.5rem;
          &::after {
            margin: 0.5rem 0;
            content: "";
            display: block;
            height: 1px;
            width: 11rem;
            background: white;
          }
        }
        h1 {
          font-size: 64px;
          max-width: 35rem;
        }
      }
      .image {
        margin: auto;
        position: relative;
        img {
          max-height: 35rem;
          max-width: 35rem;
          display: grid;
        }
        &::after {
          content: "";
          display: block;
          position: absolute;
          top: -1.75rem;
          right: -1.75rem;
          height: 100%;
          width: 100%;
          background: rgba(255, 255, 255, 0.2);
          backdrop-filter: blur(10px);
          border: 6px solid white;
          box-sizing: border-box;
          z-index: -1;
        }
      }
      .hero-thumbnail {
        position: absolute;
        right: 1.5rem;
        height: min-content;
        width: 12rem;
        max-height: 32rem;
        top: 50%;
        transform: translateY(-50%);
        overflow-y: hidden;
        .thumbnail-wrapper {
          display: grid;
          grid-gap: 0.5rem;
          padding: 0.5rem;
          transition: transform 0.4s ease-in-out;
          img {
            width: 100%;
            height: 10rem;
            display: grid;
            object-fit: cover;
            cursor: pointer;
            transition: box-shadow 0.2s, opacity 0.2s linear;
            &:not(.selected) {
              opacity: 0.4;
            }
            &.selected {
              box-shadow: 0 0 0 5px rgb(113, 113, 113);
            }
          }
        }
      }
    }
    .react-multi-carousel-list {
      overflow: visible !important;
    }
    .dot {
      transform: translateY(2.5rem);
      align-items: center;
      li {
        height: min-content !important;
      }
      button {
        border: none;
        border-radius: 0;
        width: 1.5rem;
        background-color: rgba(255, 255, 255, 0.22) !important;
        margin-right: 1rem;
        height: 2px;
      }
      .react-multi-carousel-dot--active {
        button {
          height: 3px;
          width: 3rem;
          background-color: rgba(255, 255, 255, 0.6) !important;
        }
      }
    }
    .react-multiple-carousel__arrow {
      z-index: 10;
      border-radius: 0;
      height: 100%;
      width: 4rem;
      opacity: 0;
      background: radial-gradient(
        circle,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      &:hover {
        opacity: 1;
      }
    }
    .react-multiple-carousel__arrow--right {
      right: 0;
    }
    .react-multiple-carousel__arrow--left {
      left: 0;
    }
  }
  height: 750px;
}

@media only screen and (max-width: 1350px) {
  .hero {
    .wrapper {
      .hero-item {
        max-width: 95vw;
      }
    }
  }
}
@media only screen and (max-width: 1100px) {
  .hero {
    .wrapper {
      .hero-item {
        .hero-thumbnail {
          width: 8rem;
          max-height: 24rem;
          .thumbnail-wrapper {
            img {
              height: 7.45rem;
            }
          }
        }
      }
    }
  }
}
