@import "./components/styles/variables.scss";

button {
  &.clear {
    border: none;
    outline: none;
    background: none;
  }
  &.btn {
    padding: 0.5rem 1rem;
    font-size: 0.95rem;
    font-weight: 600;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    &.primary {
      background: $gold;
      &:active {
        background: #b9a267;
      }
    }
  }
}

.rank-badge {
  background: white;
  border-radius: 50%;
  .number {
    font-weight: 600;
  }
}

#portal {
  z-index: 100;
  top: 0;
  left: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-areas: "main";
  place-items: center;
  pointer-events: none;
  * {
    pointer-events: all;
  }
  .modalBackdrop {
    height: 100%;
    width: 100%;
    grid-area: main;
    background: rgba(0, 0, 0, 0.4);
  }
  .modal {
    z-index: 10;
    grid-area: main;
    position: relative;
    & ~ .modalBackdrop {
      z-index: 10;
      & ~ .modal {
        z-index: 10;
      }
    }
  }
}
